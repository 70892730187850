import React from 'react';
import styles from '../../styles/UniversityQuizAppStyles/InstructionsModal.module.css';
import { useTranslation } from 'react-i18next';

const ProfessionModal = ({ profession, onClose, universityData }) => {
  const{ t } = useTranslation();

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button
          className={styles.closeButton}
          onClick={onClose}
        >
          ✕
        </button>
        <h2 className={styles.modalTitle}>
          {profession.name}
        </h2>
        
        <div className={styles.instructionsList}>
          <p className={styles.instructionItem}>
            {profession.description_long}
          </p>
          <p className={styles.instructionItem}>
            {t('resultPage.salaryUz', { salary: profession.salary_uz.toLocaleString() })} UZS
          </p>
          <p className={styles.instructionItem}>
            {t('resultPage.salaryWorld', { salary: profession.salary_wrld })} USD
          </p>
        </div>
        
        <a 
          href={`${profession.courses[0].link}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className={styles.readyButton}>
            {t('resultPage.becomeSpecialist')}
          </button>
        </a>
      </div>
    </div>
  );
};

export default ProfessionModal;