import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const styles = `
  :root {
    --primary-900: #323563;
    --primary-800: #40437f;
    --primary-700: #4e529a;
    --secondary-color: #FAFAFA;
    --gradient-start: #40437f;
    --gradient-end: #242648;
  }

  .container {
    min-height: 100vh;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    background: linear-gradient(135deg, var(--gradient-start) 0%, var(--gradient-end) 100%);
    color: var(--secondary-color);
    overflow-x: hidden;
  }

  .hero {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
    overflow: hidden;
  }

  .hero::before {
    content: '';
    position: absolute;
    width: 150%;
    height: 150%;
    background: radial-gradient(circle at center, rgba(64, 67, 127, 0.1) 0%, transparent 70%);
    top: -25%;
    left: -25%;
    z-index: 0;
    animation: pulse 8s infinite;
  }

  @keyframes pulse {
    0% { transform: scale(1); opacity: 0.5; }
    50% { transform: scale(1.2); opacity: 0.3; }
    100% { transform: scale(1); opacity: 0.5; }
  }

  .content {
    position: relative;
    z-index: 1;
    max-width: 1200px;
    margin: 0 auto;
  }

  .language-selector {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 2;
  }

  .lang-dropdown {
    position: relative;
    display: inline-block;
  }

  .lang-selected {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: var(--secondary-color);
    padding: 0.5rem 2.5rem 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    backdrop-filter: blur(10px);
    position: relative;
    display: flex;
    align-items: center;
    min-width: 100px;
  }

  .construction-badge {
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.85rem;
    color: rgba(255, 255, 255, 0.7);
    transform: translateY(20px);
    opacity: 0;
    animation: fadeInUp 0.8s ease 1.2s forwards;
    z-index: 10;
    transition: all 0.3s ease;
  }

  .construction-badge:hover {
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.9);
  }

  @media (max-width: 768px) {
    .construction-badge {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%) translateY(20px);
    }
    
    .construction-badge.visible {
      transform: translateX(-50%) translateY(0);
    }
  }

  .lang-selected:after {
    content: '';
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--secondary-color);
    transition: transform 0.3s ease;
  }

  .lang-dropdown.open .lang-selected:after {
    transform: translateY(-50%) rotate(180deg);
  }

  .lang-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 0.5rem;
    background: rgba(36, 38, 72, 0.95);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);
  }

  .lang-dropdown.open .lang-options {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .lang-option {
    padding: 0.5rem 1rem;
    color: var(--secondary-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: left;
    width: 100%;
    border: none;
    background: none;
    font-size: 0.9rem;
  }

  .lang-option:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  .lang-option.active {
    background: rgba(255, 255, 255, 0.15);
  }

  .logo-container {
    margin-bottom: 2rem;
    transform: translateY(-20px);
    opacity: 0;
    animation: fadeInUp 0.8s ease forwards;
  }

  .logo {
    width: 120px;
    height: 120px;
    filter: drop-shadow(0 0 20px rgba(64, 67, 127, 0.3));
  }

  .title {
    font-size: 4rem;
    font-weight: 800;
    background: linear-gradient(135deg, #fff 0%, rgba(255,255,255,0.8) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 1rem 0;
    transform: translateY(-20px);
    opacity: 0;
    animation: fadeInUp 0.8s ease 0.2s forwards;
  }

  .description {
    font-size: 1.25rem;
    max-width: 800px;
    margin: 1.5rem auto;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.9);
    transform: translateY(-20px);
    opacity: 0;
    animation: fadeInUp 0.8s ease 0.4s forwards;
  }

  .coming-soon {
    margin: 2rem 0;
    transform: translateY(-20px);
    opacity: 0;
    animation: fadeInUp 0.8s ease 0.6s forwards;
  }

  .text {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-color);
    transition: opacity 0.5s ease-in-out;
  }

  .audience-section {
    margin: 2rem 0;
    transform: translateY(-20px);
    opacity: 0;
    animation: fadeInUp 0.8s ease 0.8s forwards;
  }

  .audience-buttons {
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin: 2rem 0;
  }

  .audience-button {
    padding: 1rem 2.5rem;
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.05);
    color: var(--secondary-color);
    font-size: 1.1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: 200px;
    backdrop-filter: blur(10px);
  }

  .audience-button:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .audience-button.active {
    background: var(--secondary-color);
    color: var(--gradient-start);
    border-color: var(--secondary-color);
    box-shadow: 0 4px 12px rgba(255, 255, 255, 0.2);
  }

  .features {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 3rem;
    opacity: 0;
    animation: fadeInUp 0.8s ease 1s forwards;
    flex-wrap: wrap;
  }

  .feature {
    background: rgba(255, 255, 255, 0.05);
    padding: 2rem;
    border-radius: 12px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 300px;
    text-align: center;
    transition: transform 0.3s ease;
  }

  .feature:hover {
    transform: translateY(-5px);
  }

  .feature h3 {
    color: var(--secondary-color);
    margin-bottom: 1rem;
  }

  .feature p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.95rem;
    line-height: 1.5;
  }

  .footer {
    position: relative;
    z-index: 1;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    margin-top: auto;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .footer-links {
    display: flex;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
  }

  .link {
    color: var(--secondary-color);
    text-decoration: none;
    font-size: 0.9rem;
    opacity: 0.7;
    transition: all 0.3s ease;
  }

  .link:hover {
    opacity: 1;
  }

  .copyright {
    color: var(--secondary-color);
    opacity: 0.7;
    font-size: 0.85rem;
    text-align: center;
    margin-top: 1rem;
  }

  @keyframes fadeInUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    .title {
      font-size: 2.5rem;
    }
    
    .description {
      font-size: 1.1rem;
      padding: 0 1rem;
    }

    .audience-buttons {
      flex-direction: column;
      padding: 0 1rem;
    }

    .audience-button {
      width: 100%;
      min-width: unset;
    }

    .features {
      flex-direction: column;
      align-items: center;
      padding: 0 1rem;
    }

    .feature {
      width: 100%;
      max-width: 340px;
    }

    .footer-links {
      gap: 1.5rem;
      padding: 0 1rem;
    }
  }
`;

const LandingPage = () => {
  const { t, i18n } = useTranslation();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('students');

  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  const changeLanguage = (lng) => {
    setIsTransitioning(true);
    setTimeout(() => {
      i18n.changeLanguage(lng);
      setIsTransitioning(false);
    }, 300);
  };

  const renderFeatures = () => {
    if (activeSection === 'companies') {
      return (
        <div className="features">
          <div className="feature">
            <h3>{t('landing.companies.talent.title')}</h3>
            <p>{t('landing.companies.talent.description')}</p>
          </div>
          <div className="feature">
            <h3>{t('landing.companies.assessment.title')}</h3>
            <p>{t('landing.companies.assessment.description')}</p>
          </div>
          <div className="feature">
            <h3>{t('landing.companies.analytics.title')}</h3>
            <p>{t('landing.companies.analytics.description')}</p>
          </div>
        </div>
      );
    }

    return (
      <div className="features">
        <div className="feature">
          <h3>{t('landing.features.assessment.title')}</h3>
          <p>{t('landing.features.assessment.description')}</p>
        </div>
        <div className="feature">
          <h3>{t('landing.features.guidance.title')}</h3>
          <p>{t('landing.features.guidance.description')}</p>
        </div>
        <div className="feature">
          <h3>{t('landing.features.matching.title')}</h3>
          <p>{t('landing.features.matching.description')}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <html lang={i18n.language} />
        <title>{t('meta.title')}</title>
        <meta name="description" content={t('meta.description')} />
        <meta name="keywords" content={t('meta.keywords')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://workxplorer.uz" />
        <meta property="og:title" content={t('meta.title')} />
        <meta property="og:description" content={t('meta.description')} />
        <meta property="og:image" content="https://workxplorer.uz/white_logo192.png" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://workxplorer.uz" />
      </Helmet>

      <div className="container">
        <section className="hero">
          <div className="language-selector">
            <div className={`lang-dropdown ${isDropdownOpen ? 'open' : ''}`}>
              <button 
                className="lang-selected"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {i18n.language.toUpperCase()}
              </button>
              <div className="lang-options">
                <button 
                  className={`lang-option ${i18n.language === 'en' ? 'active' : ''}`}
                  onClick={() => {
                    changeLanguage('en');
                    setIsDropdownOpen(false);
                  }}
                >
                  English
                </button>
                <button 
                  className={`lang-option ${i18n.language === 'ru' ? 'active' : ''}`}
                  onClick={() => {
                    changeLanguage('ru');
                    setIsDropdownOpen(false);
                  }}
                >
                  Русский
                </button>
                <button 
                  className={`lang-option ${i18n.language === 'uz' ? 'active' : ''}`}
                  onClick={() => {
                    changeLanguage('uz');
                    setIsDropdownOpen(false);
                  }}
                >
                  O'zbekcha
                </button>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="logo-container">
              <img 
                src="/white_logo192.png"
                alt="WorkXplorer Logo" 
                className="logo"
              />
            </div>
            
            <h1 className="title">WorkXplorer</h1>
            
            <p className="description">
              {t('landing.description')}
            </p>

            <div className="coming-soon">
              <span className="text" style={{ opacity: isTransitioning ? 0 : 1 }}>
                {t('landing.comingSoon')}
              </span>
            </div>

            <div className="audience-buttons">
              <button
                className={`audience-button ${activeSection === 'students' ? 'active' : ''}`}
                onClick={() => setActiveSection('students')}
              >
                {t('landing.buttons.forStudents')}
              </button>
              <button
                className={`audience-button ${activeSection === 'companies' ? 'active' : ''}`}
                onClick={() => setActiveSection('companies')}
              >
                {t('landing.buttons.forCompanies')}
              </button>
            </div>

            {renderFeatures()}
          </div>
        </section>

        <div className="construction-badge">
          Site under development 🚧
        </div>

        <footer className="footer">
          <div className="footer-content">
            <nav className="footer-links" aria-label="Footer Navigation">
              {/*<a href="/terms" className="link">{t('footer.terms')}</a>
              <a href="/privacy" className="link">{t('footer.privacy')}</a>
              <a href="/offer" className="link">{t('footer.offer')}</a>*/}
              <a href="/contact" className="link">{t('footer.contact')}</a>
            </nav>
            <div className="copyright">
              © {new Date().getFullYear()} {t('footer.legalname')} {t('footer.copyright')}
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default LandingPage;