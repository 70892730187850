import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enLandingPage from './locales/LandingPage.en.json';
import ruLandingPage from './locales/LandingPage.ru.json';
import uzLandingPage from './locales/LandingPage.uz.json';

import enQuizTranslations from './locales/QuizWrapper.en.json';
import ruQuizTranslations from './locales/QuizWrapper.ru.json';
import uzQuizTranslations from './locales/QuizWrapper.uz.json';

import enDashboardTranslations from './locales/DashboardTranslations.en.json';
import ruDashboardTranslations from './locales/DashboardTranslations.ru.json';
import uzDashboardTranslations from './locales/DashboardTranslations.uz.json';

import enResultPage from './locales/resultPage.en.json';
import ruResultPage from './locales/resultPage.ru.json';
import uzResultPage from './locales/resultPage.uz.json';

import enInstructionModalTranlation from './locales/InstructionModalTranslation.en.json';
import ruInstructionModalTranlation from './locales/InstructionModalTranslation.ru.json';
import uzInstructionModalTranlation from './locales/InstructionModalTranslation.uz.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { 
        translation: {
          ...enLandingPage,
          ...enQuizTranslations,
          ...enDashboardTranslations,
          ...enResultPage,
          ...enInstructionModalTranlation
        }
      },
      ru: { 
        translation: {
          ...ruLandingPage,
          ...ruQuizTranslations,
          ...ruDashboardTranslations,
          ...ruResultPage,
          ...ruInstructionModalTranlation
        }
      },
      uz: { 
        translation: {
          ...uzLandingPage,
          ...uzQuizTranslations,
          ...uzDashboardTranslations,
          ...uzResultPage,
          ...uzInstructionModalTranlation
        }
      },
    },
    lng: 'uz', // Set Uzbek as the default language
    fallbackLng: 'uz', // Set Uzbek as the fallback language
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;